<template>
  <footer class="text-[#464646] site-footer bg-[#f0f0f0] isolate" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
      <div class="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 xl:gap-8">
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-8 col-span-1 sm:col-span-3">
          <div class="col-span-1 sm:col-span-1">
            <div>
              <h3
                class="mb-6 font-bold border-b border-[#464646] theme-text-2xl text-[28px] text-[#464646] uppercase py-1">
                Consort Homes
              </h3>

              <p class="mb-4">
                At Consort Homes we take a smart "responsible approach" to
                building comfortable, long lasting, energy efficient and
                environmentally responsible new homes in the St. Louis and
                surrounding areas.
              </p>

              <p class="mb-4">
                Consort Homes always builds in the best locations around the St.
                Louis Metro area and provides the best value with you in mind.
                If you're looking to build or buy a new home in St. Louis, think
                Consort Homes!
              </p>

              <p>
                <a href="/about/why-consort/" class="underline">Learn More About Us</a>
              </p>
            </div>
          </div>
          <div class="col-span-1 sm:col-span-2">
            <div>
              <h3
                class="mb-6 font-bold border-b border-[#464646] theme-text-2xl text-[28px] text-[#464646] uppercase py-1">
                Where We Build
              </h3>
              <ul role="list" class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4">
                <li v-for="item in navigation.neighborhoods" :key="item.id">
                  <NuxtLink :href="item.href"
                    class="hover:underline text-xl leading-none text-[#464646] !font-semibold !font-figtree theme-text-body">
                    {{ item.name.title
                    }}<span v-if="item.name.city">
                      in {{ item.name.city }}</span>
                  </NuxtLink>
                  <br />
                  <span class="text-[#464646] theme-text-base !text-sm">
                    {{ item.subtitle }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="relative xl:-top-4 p-4 mt-8 overflow-hidden xl:mt-0 col-span-2 lg:col-span-1 bg-gray-300 rounded-2xl h-[400px]">
          <div
            class="w-full overflow-hidden h-[140px] absolute top-0 inset-x-0 bg-gradient-to-b to-primary-500 from-primary-800">
            <div class="absolute top-0 left-0 right-0 bg-gradient-to-r from-primary-300/80 to-primary-100/5 bottom-3">
            </div>
            <img src="/img/osc_consort_homes.png" class="w-auto h-[160px] absolute -top-2 -right-4" alt=""
              loading="lazy" />
            <div class="absolute inset-5">
              <h3 class="!font-semibold !text-3xl text-white drop-shadow-text theme-text-2xl mb-2">
                Questions?
              </h3>
              <h4 class="ml-2 drop-shadow-text theme-text-sm text-white">
                We're here to help!
              </h4>
            </div>
          </div>
          <div class="w-full h-[88px] bg-white absolute bottom-0 inset-x-0"></div>
          <div class="absolute border border-2 rounded-2xl border-white/50 inset-1">
            <div class="z-10 mt-[170px] w-full flex justify-center flex-col gap-4 w-5/6 mx-auto">
              <NuxtLink to="/contact-us/contact-us/"
                class="inline-flex items-center justify-center w-full px-3 py-2 mx-auto text-sm font-medium text-white border border-transparent theme-text-lg bg-primary-500 leading-4 rounded-md shadow-sm hover:bg-primary-500/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Ask a Question Now
              </NuxtLink>
              <NuxtLink to="/contact-us/request-an-appointment/"
                class="inline-flex items-center justify-center w-full px-3 py-2 mx-auto text-sm font-medium bg-white border border-gray-300 theme-text-lg text-primary-500 shadow-sm leading-4 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                Visit Us
              </NuxtLink>
            </div>
            <div class="flex justify-center w-full absolute bottom-[69px]">
              <NuxtLink to="tel:636-489-8384" target="_new"
                class="inline-flex items-center border border-transparent text-sm font-medium !font-figtree !leading-4 py-1 px-6 rounded-full shadow-sm text-white bg-[#858D9B] hover:bg-[#858D9B]/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                CALL US
              </NuxtLink>
            </div>
            <div class="flex justify-center w-full absolute bottom-[30px]">
              <NuxtLink to="tel:636-489-8384" target="_new" class="flex gap-1 text-[#6B7280] theme-text-lg"><img
                  src="/img/phone-icon.svg" class="inline-block" loading="lazy" />(636)
                489-8384</NuxtLink>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t">
        <div class="relative px-3 mx-auto bg-[#f0f0f0] space-x-3 -top-4 w-fit">
          <NuxtLink v-for="item in navigation.social" :key="item.name" :href="item.href" target="_new" class="">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="inline-block w-8 h-8 opacity-70" aria-hidden="true" />
          </NuxtLink>
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-center w-full mt-4 mb-12 gap-5 opacity-80">
        <NuxtLink
          href="https://www.bbb.org/us/mo/chesterfield/profile/home-builders/consort-homes-llc-0734-310045660/#sealclick"
          target="_new">
          <img src="/img/footer/icon-bbb-logo.png" class="w-[125px] h-[48px]" loading="lazy" />
        </NuxtLink>
        <NuxtLink href="http://www.energystar.gov/" target="_new">
          <img src="/img/footer/icon-energy-star.png" class="w-[128px] h-[62px]" loading="lazy" />
        </NuxtLink>
        <NuxtLink href="http://www.stlhba.com/" target="_new">
          <img src="/img/footer/icon-stlhba.png" class="w-[63px] h-[70px]" loading="lazy" />
        </NuxtLink>
        <NuxtLink href="https://www.chesterfieldmochamber.com/" target="_new">
          <img src="/img/footer/icon-chesterfield-chamber.png" class="w-[128px] h-[72px]" loading="lazy" />
        </NuxtLink>
        <NuxtLink href="https://www.realproducersmag.com/locations/st-louis-real-producers-9137/" target="_new">
          <img src="/img/footer/icon-stl-partner-badge.png" class="w-[64px] h-[81px]" loading="lazy" />
        </NuxtLink>
      </div>
      <p class="min-w-full text-xs text-[#464646] font-inter">
        <span class="font-semibold">© Copyright 2010-2025 Consort Homes. All Rights Reserved.</span><br />
        All plans, prices and information subject to change without notice.
        Consort Homes images shown are only artist's renderings and may vary in
        slight detail from actual homes built. Actual final product drawings and
        specifications can be viewed in the neighborhood sales office where you
        are interested in purchasing. Consort Homes, in an effort to
        continuously improve its product, reserves the right to change product
        offerings as necessary and images and data shown may not reflect current
        offerings. The information presented here is only a guide and is not a
        legal promise or commitment to a price or any other offering. For the
        most current information, see a sales manager in the neighborhood of
        your choice.
      </p>
    </div>
  </footer>
</template>

<script setup>
import { defineComponent, h } from "vue";

const props = defineProps({
  neighborhoods: Array,
});

const navigation = {
  neighborhoods: [],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/consorthomes",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "#9CA3AF", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "https://instagram.com/consorthomes/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "#9CA3AF", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/consort-homesstl/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "#9CA3AF", viewBox: "0 0 24 24" }, [
            h("path", {
              d: "M22.23 0H1.77C.79 0 0 .79 0 1.77v20.46C0 23.21.79 24 1.77 24h20.46c.98 0 1.77-.79 1.77-1.77V1.77C24 .79 23.21 0 22.23 0zM7.46 20.49H3.77V9.03h3.69v11.46zM5.62 7.56c-1.13 0-2.04-.92-2.04-2.05s.92-2.05 2.04-2.05 2.05.92 2.05 2.05-.92 2.05-2.05 2.05zm15.82 12.93h-3.69v-5.64c0-1.34-.03-3.07-1.87-3.07-1.87 0-2.16 1.46-2.16 2.97v5.74h-3.69V9.03h3.54v1.56h.05c.49-.93 1.69-1.91 3.48-1.91 3.72 0 4.41 2.45 4.41 5.64v6.24z",
            }),
          ]),
      }),
    },
  ],
};

watchEffect(() => {
  props.neighborhoods.forEach((item) =>
    navigation.neighborhoods.push({
      name: {
        title: item.label,
        city: item.connectedNode?.node?.neighborhood?.city,
      },
      href: item.connectedNode?.node?.uri
        ? item.connectedNode.node.uri
        : item.uri,
      subtitle: getNeighborhoodNavSubtitle(
        item.connectedNode?.node?.types,
        getRoundedPrice(item.connectedNode?.node?.basePrice),
        item.connectedNode?.node?.neighborhood?.status,
      ),
    }),
  );
});
</script>
